<template>
  <b-row no-gutters>
    <b-col>
      <div class="mt-5 mb-3">
        <b-container>
          <h1>Výsledky</h1>
        </b-container>

        <b-jumbotron
          bg-variant="primary"
          text-variant="white"
          class="mt-3 px-0 py-4"
        >
          <b-container>
            <b-row align-v="center">
              <b-col>
                <LoadingSpinner :is-loaded="!!selectedRound">
                  <h2 class="my-2">
                    {{ selectedContestRoundName }}
                  </h2>
                </LoadingSpinner>
              </b-col>
              <b-col md="auto">
                <LoadingSpinner :is-loaded="contestsLoaded && roundsLoaded">
                  <RoundSelectionDropdown
                    :contests="contests"
                    :rounds="rounds"
                    view-name="results"
                    right
                  />
                </LoadingSpinner>
              </b-col>
            </b-row>
          </b-container>
        </b-jumbotron>

        <LoadingSpinner :is-loaded="tablesLoaded">
          <b-container fluid>
            Kategórie:
            <b-link
              v-for="table of selectedTables"
              :key="`result-table-link-${table.id}`"
              v-scroll-to="{ el: `#${categoryId(table)}`, ...scrollConfig }"
              :href="`#${categoryId(table)}`"
              class="ml-2"
            >
              {{ table.name }}
            </b-link>

            <div
              v-for="table of selectedTables"
              :key="`result-table-${table.id}`"
            >
              <h4 class="mt-4">
                Kategória {{ table.name }}
              </h4>
              <ResultTable
                :id="categoryId(table)"
                :table-id="table.id"
                :round-id="selectedRound.id"
                :user-id="$root.state.user ? $root.state.user.id : null"
                @ready="tableReady(categoryId(table))"
              />
            </div>
          </b-container>
        </LoadingSpinner>
      </div>
    </b-col>

    <b-col
      xl="2"
      class="sticky-below-navbar category-nav d-none d-xl-block p-2"
    >
      <h6
        v-if="selectedRound"
        class="mx-0 my-2"
      >
        {{ selectedContestRoundName }}
      </h6>
      <ScrollNavigator
        v-if="tablesLoaded"
        :items="scrollNavigatorItems"
        :scroll-config="scrollConfig"
      />
    </b-col>
  </b-row>
</template>

<script>
import { apiContests, apiResults, apiRounds, LoadingSpinner,
         RoundSelectionDropdown, utils } from 'frontend-common'
import ResultTable from '@/components/ResultTable.vue'
import ScrollNavigator from '@/components/utils/ScrollNavigator.vue'
import VueScrollTo from 'vue-scrollto'

export default {
  name: 'Results',
  components: {
    LoadingSpinner,
    RoundSelectionDropdown,
    ResultTable,
    ScrollNavigator,
  },
  mixins: [
    apiContests,
    apiResults,
    apiRounds,
  ],
  props: {
    roundId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      scrollConfig: {
        duration: 250,
        offset: -150,
        delay: 500,
      },
      contests: [],
      contestsLoaded: false,
      rounds: [],
      roundsLoaded: false,
      selectedRound: null,
      tables: [],
      tableMap: {},
      tablesLoaded: false,
      selectedTableIds: [],
    }
  },
  computed: {
    selectedContestRoundName() {
      if (!this.selectedRound || !this.selectedRound.contest) return ''
      return utils.fullRoundName(this.selectedRound.contest.name, this.selectedRound.name)
    },
    selectedTables() {
      if (!this.tablesLoaded) return []
      return this.selectedTableIds.map(tableId => this.tableMap[tableId])
    },
    scrollNavigatorItems() {
      const currentContest = this.$root.state.competition.current_round ?
        this.$root.state.competition.current_round.contest.id : null

      return this.selectedTables.map(table => ({
        scrollId: `#${this.categoryId(table)}`,
        text: `${table.name}`,
        highlighted: table.contest.id === currentContest && this.$root.userCategory.id &&
          table.properties.categories.includes(this.$root.userCategory.id),
      }))
    },
  },
  watch: {
    $route(to, from) {
      // if we didn't check this, every url hash change would reload the round
      if (String(to.params.roundId) !== String(from.params.roundId)
        && this.roundsLoaded && this.tablesLoaded) {
        this.loadTableContents()
      }
    },
    '$root.stateLoaded': 'redirectToRound',
  },
  mounted() {
    this.apiContests().
      then(response => this.contests = response.reverse()).
      finally(() => this.contestsLoaded = true)

    const roundsPromise = this.apiRounds().
      then(response => this.rounds = response).
      then(() => this.redirectToRound()).
      finally(() => this.roundsLoaded = true)

    const tablesPromise = this.apiResultTables().
      then(response => {
        this.tables = response
        this.tableMap = {}
        for (const table of response) {
          this.tableMap[table.id] = table
        }
      }).
      finally(() => this.tablesLoaded = true)

    Promise.all([roundsPromise, tablesPromise]).then(this.loadTableContents)
  },
  methods: {
    redirectToRound() {
      if (this.roundId !== null || this.rounds.length === 0 || !this.$root.stateLoaded) return false

      const previous_round = this.$root.state.competition.previous_round
      this.$router.replace({
        name: 'results',
        params: { roundId: previous_round ? previous_round.id : this.rounds[0].id },
        hash: this.$root.userCategory.name ?
          `#kategoria-${this.$root.userCategory.name.substring(0, 1)}` : '',
      })
      return true
    },
    loadTableContents() {
      this.selectedRound = this.rounds.find(round => round.id === this.roundId)
      this.selectedTableIds = this.tables.
        filter(table => table.properties.round_id === this.roundId).
        map(table => table.id)
    },
    categoryId(table) {
      return `kategoria-${table.name.substring(0, 1)}`
    },
    tableReady(hash) {
      if (this.$route.hash && this.$route.hash === '#' + hash) {
        setTimeout(
          () => VueScrollTo.scrollTo(this.$route.hash, this.scrollConfig),
          this.scrollConfig.delay,
        )
      }
    },
  },
}
</script>

<style scoped>
.category-nav {
  font-size: 90%;
}
</style>
